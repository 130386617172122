import React from 'react'
import Div from '../Div'
import ContactInfoWidget from '../Widget/ContactInfoWidget'
import MenuWidget from '../Widget/MenuWidget'
import Newsletter from '../Widget/Newsletter'
import SocialWidget from '../Widget/SocialWidget'
import TextWidget from '../Widget/TextWidget'
import './footer.scss'

export default function Footer({copyrightText, logoSrc, logoAlt, text}) {
  const copyrightLinks = [
    {
      title: 'Terms & Conditions',
      href: '/'
    },
    {
      title: 'Privacy Policy',
      href: '/'
    }
  ]
  
  const serviceMenu = [
    {
      title: 'Web Development',
      href: '/'
    },
    {
      title: 'Branding',
      href: '/'
    },
    {
      title: 'Digital Marketing',
      href: '/'
    },
    {
      title: 'Software Development',
      href: '/'
    },
  ]

  return (
    <footer className="cs-fooer">
      <Div className="cs-fooer_main">
        <Div className="container">
          <Div className="row">
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <TextWidget 
                  logoSrc='/images/logo-antmark1.png' 
                  logoAlt='Logo'
                  text =' Delivering cost effective and scalable solutions to our clients across the globe.'
                />
                <SocialWidget/>
              </Div>
            </Div>
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <ContactInfoWidget withIcon title='Contact Us'/>
              </Div>
            </Div>
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <MenuWidget menuItems={serviceMenu} menuHeading='Services'/>
              </Div>
            </Div>
            
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <Newsletter 
                  title='Newsletter' 
                  subtitle='Subscribe to our newsletter and stay updated!' 
                  placeholder='Type your email'
                />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>

      <Div className="cs-widget_title1">
      <Div className="container">
       Best Website Design Company in Hyderabad - Best Website Development Company in Hyderabad - Best Graphic Design Company in Hyderabad - Best Website Design Company in Tandur - Best Social Media Marketing Company in Hyderabad - Best Digital Marketing Company in Hyderabad - Best Software Company in Hyderabad - Best Advertising Agency in Hyderabad - Best App Development Company in Hyderabad- Best Digital Agency in Hyderabad - Best Branding Agency in Hyderabad - Best Branding Company in Hyderabad - Best Packaging Agency in Hyderabad - Best Logo Design Company in Hyderabad - Top Website Designing Company in Hyderabad - Antmark is Top Rated Website Design or Development Company in Hyderabad</Div>
      </Div>
      
      <Div className="container">
        <Div className="cs-bottom_footer">
          <Div className="cs-bottom_footer_left">
            <Div className="cs-copyright">Copyright ©2023 Antmark.</Div>
          </Div>
          <Div className="cs-bottom_footer_right">
            <MenuWidget menuItems={copyrightLinks} variant=' cs-style2'/>
          </Div>
        </Div>
      </Div>
    </footer>
  )
}
