import React, { useEffect } from 'react'
import pageTitle from "../../scss/helper/index";
import Cta from '../Cta'
import PageHeading from '../PageHeading'
import Pagination from '../Pagination'
import PostStyle2 from '../Post/PostStyle2'
import Div from '../Div'

import Spacing from '../Spacing'

export default function BlogPage() {
  pageTitle('Blogs');
  const postData = [
    {
      thumb:'/images/socialmediamarketing.jpg',
      title:'Why Social Media Is Important for Business Marketing',
      subtitle:'The world connects through the internet, and social media networks are present in the lives of millions around the world. Social media is becoming one of the most important aspects of digital marketing which provides incredible benefits by reaching millions of customers worldwide. They help you to connect with the customers, increase your brand awareness, and boost your leads and sales. ',
      date:'14 July 2023',
      category:'Marketing',
      categoryHref:'/blog',
      href:'/blog/blog-details'
    },
    {
      thumb:'/images/blog-updating-soon1.jpg',
      
      
     
    },
    
  ]

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return (
    <>
      <PageHeading 
        title='Reading makes your world bigger.'
        bgSrc='images/service_hero_bg.jpg'
        pageLinkText='Blogs'
      />
      <Spacing lg='150' md='80'/>
      <Div className="container">
        <Div className="row">
          <Div className="col-lg-10">
            {postData.map((item, index)=> (
              <Div key={index}>
                <PostStyle2 
                  thumb={item.thumb}
                  title={item.title}
                  subtitle={item.subtitle}
                  date={item.date}
                  category={item.category}
                  categoryHref={item.categoryHref}
                  href={item.href}
                />
                {postData.length>index+1 && <Spacing lg='95' md='60'/>}
              </Div>
            ))}
            <Spacing lg='60' md='40'/>
            <Pagination/>
          </Div>
          
        </Div>
      </Div>
      <Spacing lg='150' md='80'/>
      <Div className="container">
        <Cta 
         title="Let’s discuss to make <br />something <i>cool</i> together"
         btnText="Apply For Meeting"
         btnLink="/contact"
         bgSrc="/images/cta_bg_31.jpg"
        />
      </Div>
    </>
  )
}
