import React, { useEffect } from 'react';
import pageTitle from "../../scss/helper/index";
import Cta from '../Cta';
import Div from '../Div';
import PageHeading from '../PageHeading';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import ServiceList from '../ServiceList';

export default function CareerPage() {
  pageTitle('Career');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <PageHeading
        title="Team work makes the dream work."
        bgSrc='images/service_hero_bg.jpg'
        pageLinkText="careers"
      />
      
        
    
       
      

 {/* Start Services Section */}
 <Spacing lg="55" md="30" />
      <Div className="container">
        <SectionHeading
          title="Current Openings"
          subtitle="Apply now!"
          variant="cs-style1 text-center"
        />
        <Spacing lg="70" md="45" />
        <ServiceList />
      </Div>
      {/* End Services Section */}
      
      <Spacing lg="60" md="80" />
      <Div className="container text-center">
        <Div className="row col-lg-12 ">
          <h2 className="cs-font_38 cs-m0">How to Apply?</h2>
          <Spacing lg="20" md="15" />
          <p className="cs-m0" style={{ fontSize: 18 }}>
          When applying for a job at Antmark, we encourage you to showcase your skills, experience, and achievements in your resume/cv. We are always looking for talented professionals who are passionate about innovation and excellence, and who are committed to delivering exceptional results for our clients.
          <Spacing lg="25" md="25" />
We value diversity and inclusion, and we are committed to creating a work environment that is supportive, collaborative, and respectful. We offer competitive salaries, comprehensive benefits packages, and opportunities for professional development and career advancement. We believe in work-life balance, and we offer flexible schedules and remote work options to help our team members achieve their personal and professional goals.
          </p> <Spacing lg="25" md="25" />
        </Div>
      </Div>
      
      
      <div className="container">
        <Div className="row">
          <Div className="col-sm-6">
            <img
            src="/images/antmark-office.jpg"
             
              alt="Thumb"
              className="w-100 cs-radius_5"
            />
            <Spacing lg="25" md="25" />
          </Div>
          <Div className="col-sm-6">
            <img
               src="/images/case_study_img_21.jpg"
              alt="Thumb"
              className="w-100 cs-radius_5"
            />
            <Spacing lg="25" md="25" />
          </Div>
        </Div></div>
      <Spacing lg="145" md="80" />
      {/* Start CTA Section */}
      
      <Cta 
        title='career@antmark.in' 
        bgSrc='/images/cta_bg_2.jpeg'
        variant='rounded-0'
      />
     
      {/* End CTA Section */}
    </>
  );
}
