import React from 'react'
import { Link } from 'react-router-dom'
import { Icon } from '@iconify/react';
import Div from '../Div';

export default function SocialWidget() {
  return (
    <Div className="cs-social_btns cs-style1">
       <Link to='http://wa.me/918977881809' className="cs-center">
        <Icon icon="fa6-brands:whatsapp" />              
      </Link>
      <Link to='http://in.linkedin.com/company/antmark' className="cs-center">
        <Icon icon="fa6-brands:linkedin-in" />
      </Link>
      <Link to='http://www.facebook.com/antmark.in' className="cs-center">
        <Icon icon="fa6-brands:facebook" />               
      </Link>
      <Link to='http://www.instagram.com/antmark.in' className="cs-center">
        <Icon icon="fa6-brands:instagram" />
      </Link>
    </Div>
  )
}
