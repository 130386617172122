import React, { useEffect } from 'react';
import pageTitle from "../../scss/helper/index";
import Cta from '../Cta';
import FunFact from '../FunFact';
import PageHeading from '../PageHeading';
import Div from '../Div';
import SectionHeading from '../SectionHeading';

import Spacing from '../Spacing';

export default function AboutPage() {
  pageTitle('About');

  const funfaceData = [
    {
      title: 'Global Clients',
      factNumber: '95',
    },
    {
      title: 'Projects Completed',
      factNumber: '238',
    },
    {
      title: 'Awards Won',
      factNumber: '02',
    },
    {
      title: 'Team Members',
      factNumber: '11',
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* Start Page Heading Section */}
      <PageHeading
        title="The more you know, the more you believe."
        bgSrc='images/service_hero_bg.jpg'
        pageLinkText="About Us"
      />
      {/* End Page Heading Section */}

      

      <Spacing lg="60" md="80" />
      <Div className="container text-center">
        <Div className="row col-lg-12 ">
          <h2 className="cs-font_38 cs-m0">About Company</h2>
          <Spacing lg="20" md="15" />
          <p style={{ fontSize: 23 }} className="cs-m0"> 
          Founded by Aditya Mengji in 2020, Antmark has quickly become a prominent provider of digital services, offering solutions such as web development, branding, digital marketing, and software development. Under Aditya's leadership, the company has achieved remarkable success and earned a strong reputation in the industry. 
          <Spacing lg="20" md="15" />
Aditya's expertise and vision have played a crucial role in Antmark's growth and success. With his guidance, the company delivers exceptional solutions to help clients reach their goals. Antmark's team of skilled professionals, assembled by Aditya, is dedicated to providing top-quality services to clients.
<Spacing lg="20" md="15" />
Antmark stands out for its focus on innovation, constantly staying ahead of the curve with the latest technologies and trends in the digital world. This commitment ensures that clients receive cutting-edge solutions that give them a competitive advantage.
<Spacing lg="20" md="15" />
Collaboration is a core value at Antmark, with the company emphasizing teamwork to deliver the best results for clients. This collaborative approach ensures seamless and efficient project execution.
<Spacing lg="20" md="15" />
Client satisfaction is paramount at Antmark, with the company going above and beyond to understand each client's unique needs and tailor solutions accordingly. The team works closely with clients throughout the process, providing updates and seeking feedback to ensure the final product exceeds expectations.
<Spacing lg="20" md="15" />
Antmark's dedication to excellence was recognized in 2024 when the company received the prestigious <b>"Innovative Company Award"</b> from Jayesh Ranjan, the Principal Secretary to the Government of Telangana. This award further solidifies Antmark's position as an industry leader.
         
          </p> <Spacing lg="25" md="25" />
        </Div>
      </Div>
      
      {/* Start Why Choose Section */}
<Spacing lg="100" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-image_layer cs-style1">
              <Div className="cs-image_layer_in">
                <img
                  src="/images/about_img_7.jpg"
                  alt="About"
                  className="w-100 cs-radius_15"
                />
              </Div>
            </Div>
            <Spacing lg="0" md="40" />
          </Div>
          <Div className="col-xl-5 offset-xl-1 col-lg-6">
            <SectionHeading
              title="Why Antmark?"
              subtitle=""
              
            >
              <Spacing lg="10" md="10" /><Div className="cs-separator cs-accent_bg"></Div>
              <p className="cs-m0" style={{ fontSize: 18 }}>
              <br/>At Antmark, we believe in providing top-quality services that exceed our clients' expectations. We take pride in our work and strive to deliver the best results possible. We are dedicated to staying up-to-date with the latest trends and technologies and are committed to providing our clients with the most effective solutions possible.
              </p>
              <Spacing lg="15" md="15" />
              <p className="cs-m0" style={{ fontSize: 18 }}>
              We believe in building strong relationships with our clients. We work closely with them to ensure that their needs are met and that they are completely satisfied with the work we do. We believe in transparency and honesty and work hard to earn the trust of our clients.
              </p>
              
              
              
            </SectionHeading>
          </Div>
        </Div>
      </Div><Spacing lg="125" md="70" />
      {/* End Why Choose Section */}

     

    
      {/* Start Team Section 
      <Spacing lg="125" md="80" />
      <Div className="container">
        <SectionHeading
          title="Our Awesome <br/>Team Members"
          subtitle="We're growing rapidly"
          variant="cs-style1"
        />
        <Spacing lg="75" md="45" />
        <TeamSlider />
      </Div>
      <Spacing lg="85" md="45" />
      {/* End Team Section */}

     

      

      

      
      
      {/* End LogoList Section */}

      
      
      
      <Spacing lg="145" md="140" />


     {/* Start FunFact Section */}
     <div className="container">
        <FunFact
          variant="cs-type1"
          title="We're proud of our journey"
          subtitle={"Started in 2020"}
    
          data={funfaceData}
        />
      </div>
      {/* End FunFact Section */}

      
      

      {/* Start CTA Section */}
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Cta
         title="Let’s discuss to make <br />something <i>cool</i> together"
         btnText="Apply For Meeting"
         btnLink="/contact"
         bgSrc="/images/cta_bg_31.jpg"
        />
      </Div>
      {/* End CTA Section */}
    </>
  );
}
