import { Icon } from '@iconify/react';
import React, { useEffect } from 'react';
import { useState } from 'react';
import pageTitle from "../../scss/helper/index";
import Cta from '../Cta';
import PageHeading from '../PageHeading';
import Portfolio from '../Portfolio';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';

export default function PortfolioPage() {
  pageTitle('Portfolio');
  const [active, setActive] = useState('all');
  const [itemShow, setItemShow] = useState(11);
  const portfolioData = [
    {
      title: '',
      subtitle: '',
      href: '/contact',
      src: '/images/socialmedia.jpg',
      category: 'social_media',
    },
    {
      title: 'Website - Client: SVISION.HOSPITAL',
      subtitle: 'Warangal',
      href: '',
      src: '/images/svision-hospital-website-antmark.jpg',
      category: 'web_design',
    },
    {
      title: 'Website - Client: Loans For India',
      subtitle: 'Hyderabad',
      href: 'http://loansforindia.in',
      src: '/images/website-loans-for-india-antmark.jpg',
      category: 'web_design',
    },
    {
      title: 'Hoarding - Client: Swarnanjali Real Projects ',
      subtitle: 'Hyderabad',
      href: '',
      src: '/images/swarnanjali-realestate-antmark.jpg',
      category: 'Digital Marketing',
    },
    {
      title: 'Client: Bazzinga Brew',
      subtitle: 'Hyderabad',
      href: '',
      src: '/images/milk-kefir-bazzinga-antmark.jpg',
      category: 'social_media',
    },
    
    {
      title: 'Website - Client: Maiditeasy!',
      subtitle: 'Hyderabad',
      href: 'http://maiditeasy.in',
      src: '/images/maid-it-easy-website-antmark.jpg',
      category: 'web_design',
    },
    
    {
      title: 'Website - Client: Greenfleck',
      subtitle: 'Hyderabad',
      href: 'http://greenfleck.in',
      src: '/images/greenfleck-website-antmark.jpg',
      category: 'web_design',
    },
    {
      title: 'Website - Client: GetChasma.com',
      subtitle: 'Kalaburagi',
      href: 'http://getchasma.com',
      src: '/images/getchasma-website-antmark.jpg',
      category: 'web_design',
    },
    
    {
      title: 'Website - Client: Rams Group Interiors',
      subtitle: 'Hyderabad',
      href: 'http://ramsgroup.art',
      src: '/images/rams-group-interiors-antmark.jpg',
      category: 'web_design',
    },
    
    
  ];
  const categoryMenu = [
    {
      title: 'Website Development',
      category: 'web_design',
    },
    {
      title: 'Social Media',
      category: 'social_media',
    },
    {
      title: 'Branding',
      category: 'branding',
    },
    {
      title: 'Softwares',
      category: 'softwares',
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageHeading
        title="Portfolios are everything, promises are nothing."
        bgSrc='images/service_hero_bg.jpg'
        pageLinkText="Portfolio"
      />
      <Spacing lg="145" md="80" />
      <Div className="container">
        <Div className="cs-portfolio_1_heading">
          <SectionHeading title="Our recent works" subtitle="Portfolio" />
          <Div className="cs-filter_menu cs-style1">
            <ul className="cs-mp0 cs-center">
              <li className={active === 'all' ? 'active' : ''}>
                <span onClick={() => setActive('all')}>All</span>
              </li>
              {categoryMenu.map((item, index) => (
                <li
                  className={active === item.category ? 'active' : ''}
                  key={index}
                >
                  <span onClick={() => setActive(item.category)}>
                    {item.title}
                  </span>
                </li>
              ))}
            </ul>
          </Div>
        </Div>

        
        <Spacing lg="90" md="45" />
        <Div className="row">
          {portfolioData.slice(0, itemShow).map((item, index) => (
            <Div
              className={`${
                index === 3 || index === 6 ? 'col-lg-8' : 'col-lg-4'
              } ${
                active === 'all'
                  ? ''
                  : !(active === item.category)
                  ? 'd-none'
                  : ''
              }`}
              key={index}
            >
              <Portfolio
                title={item.title}
                subtitle={item.subtitle}
                href={item.href}
                src={item.src}
                variant="cs-style1 cs-type1"
              />
              <Spacing lg="25" md="25" />
            </Div>
          ))}
        </Div>

        <Div className="text-center">
          {portfolioData.length <= itemShow ? (
            ''
          ) : (
            <>
              <Spacing lg="65" md="40" />
              <span
                className="cs-text_btn"
                onClick={() => setItemShow(itemShow + 3)}
              >
                <span>Load More</span>
                <Icon icon="bi:arrow-right" />
              </span>
            </>
          )}
        </Div>
      </Div>

<div className='container'><h3>UPDATING OUR MOST RECENT WORKS SOON!</h3></div>
      
      <Spacing lg="145" md="80" />
      <Div className="container">
          <Cta
           title="Let’s discuss to make <br />something <i>cool</i> together"
           btnText="Apply For Meeting"
           btnLink="/contact"
           bgSrc="/images/cta_bg_31.jpg"
          />
        </Div>
      
    </>
  );
}
