import { Route, Routes } from "react-router-dom";
import AboutPage from "./components/Pages/AboutPage";
import BlogPage from "./components/Pages/BlogPage";
import BlogDetailsPage from "./components/Pages/BlogDetailsPage";
import ContactPage from "./components/Pages/ContactPage";
import ErrorPage from "./components/Pages/ErrorPage";
import Home from "./components/Pages/Home";
import PortfolioDetailsPage from "./components/Pages/PortfolioDetailsPage";
import ServiceDetailsPage from "./components/Pages/ServiceDetailsPage";
import ServicesPage from "./components/Pages/ServicesPage";
import TeamPage from "./components/Pages/TeamPage";
import PortfolioPage from "./components/Pages/PortfolioPage";
import TeamDetails from "./components/Pages/TeamDetails";

import Layout from "./components/Layout";
import Career from "./components/Pages/Career";
import BestWebsiteDesignCompanyInHyderabad from "./components/Pages/BestWebsiteDesignCompanyInHyderabad";
import BestWebsiteDesignCompanyInTandur from "./components/Pages/BestWebsiteDesignCompanyInTandur";
import BestDigitalMarketingCompanyInHyderabad from "./components/Pages/BestDigitalMarketingCompanyInHyderabad";
function App() {
  return (
    <>
      <Routes>
        {/* Main Layout */}
        <Route path='/' element={<Layout />}>
          {/* Home page */}
          <Route index element={<Home />} />

          {/* Agency pages */}
         
           

          {/* Other pages */}
          <Route path='about' element={<AboutPage />} />
          <Route path='service' element={<ServicesPage />} />
          <Route
            path='service/:serviceDetailsId'
            element={<ServiceDetailsPage />}
          />
          <Route path='portfolio' element={<PortfolioPage />} />
          <Route
            path='portfolio/:portfolioDetailsId'
            element={<PortfolioDetailsPage />}
          />
          <Route path='blog' element={<BlogPage />} />
          <Route path='blog/:blogDetailsId' element={<BlogDetailsPage />} />
          <Route path='contact' element={<ContactPage />} />
          <Route path='team' element={<TeamPage />} />
          <Route path='team/:teamDetails' element={<TeamDetails />} />
          <Route
            path='/career'
            element={<Career />}
          />
          <Route path='BestWebsiteDesignCompanyInHyderabad' element={<BestWebsiteDesignCompanyInHyderabad />} />

          <Route path='BestWebsiteDesignCompanyInTandur' element={<BestWebsiteDesignCompanyInTandur />} />

          <Route path='BestDigitalMarketingCompanyInHyderabad' element={<BestDigitalMarketingCompanyInHyderabad />} />
          
        {/* Alternative Layout */}
        
</Route>

        {/* 404 Error page */}
        <Route path='*' element={<ErrorPage />} />
      </Routes>
    </>
  );
}

export default App;
