
import React, { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import pageTitle from "../../scss/helper/index";
import Cta from '../Cta'
import PageHeading from '../PageHeading'
import Div from '../Div'

import Spacing from '../Spacing'




export default function BlogDetailsPage() {
  const params = useParams()
  pageTitle('Blog Details');
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
    {/* Start Page Heading Section */}
      <PageHeading 
        title='Why Social Media Is Important for Business Marketing'
        bgSrc='/images/bgpage.jpg'
        pageLinkText={params.blogDetailsId}
      />
      {/* End Page Heading Section */}

      {/* Start Blog Details */}
      <Spacing lg='150' md='80'/>
      <Div className="container">
        <Div className="row">
          <Div className="col-lg-10">

            {/* Start Details Post Content */}
            <Div className="cs-post cs-style2">
              <Div className="cs-post_thumb cs-radius_15">
                <img src="/images/socialmediamarketing.jpg" alt="Post" className="w-100 cs-radius_15" />
              </Div>
              <Div className="cs-post_info">
                <Div className="cs-post_meta cs-style1 cs-ternary_color cs-semi_bold cs-primary_font">
                  <span className="cs-posted_by">14 July 2023</span>
                  <Link to="/blog" className="cs-post_avatar">Marketing</Link>
                </Div>
                
                <p>The world connects through the internet, and social media networks are present in the lives of millions around the world. Social media is becoming one of the most important aspects of digital marketing which provides incredible benefits by reaching millions of customers worldwide. They help you to connect with the customers, increase your brand awareness, and boost your leads and sales.  </p>
                <blockquote className="cs-primary_font">
                Let’s start with one simple fact: your business needs a social media presence.
It doesn’t matter if you run a small local shop or a big national company. Social media is more than just a trend. It is an essential piece of your business marketing strategy.<br/><br/>
But by itself, social media is not enough to drive business growth. Social platforms can help you connect with your customers, increase awareness about your brand, and boost your leads and sales.
                
                </blockquote>
                
                
                <h3>Cost-Effective</h3>
                <p>As anyone can do social media marketing even with investing huge cost, it is possibly the most cost-effective way for an advertising strategy. You can create an account and sign up for free on almost all social networking platforms. Being cost-effective is crucial as it helps you accomplish a greater return on investment and helps you to hold a larger budget for other marketing and business payments.</p>
                <h3>Engaging Customers</h3>
                <p>One of the best reasons for your business to be marketing through social media is that your customers are already spending time on these platforms. It is a good way to engage and interact with customers on a personal level. By performing simple market research can help you determine which social networks your target audience uses the most. Additionally, by communicating and engaging with your customers, you can win their attention and convey your brand message. That way you can reach more audience in real time and established yourself in the market. </p>
              
              <h3>Improves Brand Loyalty</h3>
              <p>Having a social media presence makes it easier for your customers to find you and connect with you. This will help in improving customer retention and customer loyalty. Since developing a loyal customer base is one of the main goals of any business, social media should be in your strategy. Usually, brand loyalty and customer satisfaction go hand in hand. Social media is not limited to just introduce your product; it can also be used for promotional campaigns. A customer considers these to be as service channels where they can directly communicate with the business.</p>
              
              <h3>Increases Traffic</h3>
              <p>One of the major benefits of social media is that it helps to increase your website traffic. By sharing your content on social media, you are providing users to click through your website and visit it for more information. The more quality content you share on your social account, the more inbound traffic you will generate while making conversion opportunities.</p>
              
              <h3>Enhances SEO Rankings</h3>
              <p>In calculating rankings, presence in social media is becoming an essential factor. The algorithm that secures a successful ranking keeps evolving. Nowadays, it is no longer enough to simply optimize your website and update your blog regularly. Successful brands tend to have a healthy social media presence. This presence act as a signal to the search engines that your brand is valuable, credible and trustworthy. </p>
              
              
              </Div>
            </Div>
            {/* End Details Post Content */}

            
          </Div>
          
        </Div>
      </Div>
      <Spacing lg='150' md='80'/>
      {/* Start Blog Details */}

      {/* Start CTA Section */}
      <Div className="container">
        <Cta 
        title="Let’s discuss to make <br />something <i>cool</i> together"
        btnText="Apply For Meeting"
        btnLink="/contact"
        bgSrc="/images/cta_bg_31.jpg"
        />
      </Div>
      {/* End CTA Section */}
    </>
  )
}
